.general-videos-content {
  display: flex;
  width: 100%;
}
video::-webkit-media-controls-panel {
  background-image: linear-gradient(transparent,rgba(0, 0, 0, 0.524)) !important; //Transparent for your case
}



@media only screen and (max-width: 750px) {
  .general-videos-content {
    flex-direction: column-reverse;
  }
}