.course-content-course {
  padding: 30px 0;
  width: 100%;
  .course-content {
    width: 64%;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    gap: 16px;
   
    &-head {
      display: flex;
      flex-direction: column;
      gap: 24px;
      h5 {
        color: #fff;
        font-family: Lato;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
      }
      span {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        color: rgba(185, 185, 185, 1);
        font-family: 'Roboto', sans-serif !important;
      }
    }
    &-lists {
      display: flex;
      flex-direction: column;
      
      .list {
        border: 1px solid #794c00;
        display: flex;
        flex-direction: column;
        padding: 16px;
        &-first {
          cursor: pointer;
          // display: flex;
         
          align-items: center;
          justify-content: space-between;
          &-left-part {
            display: flex;
            align-items: center;
            gap: 12px;
            img {
              width: 24px;
              height: 24px;
            }
            span {
              font-family: Lato;
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.2px;
              color: rgba(243, 243, 243, 1);
            }
            .rotate {
              transform: rotate(180deg);
              //   transition: transform 0.3s ease;
            }
          }
          &-right-part {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            padding: 10px;

            span {
              font-family: Lato;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.16px;
              color: rgba(209, 209, 209, 1);
            }
          }
        }
        &-second {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          padding: 16px 0;
          gap: 20px;
          .isExpanded {
            font-size: 14px;
            color: rgba(209, 209, 209, 1);
            width: 77%;
            padding-left: 37px;
          }
          &-one {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-left {
              display: flex;
              align-items: center;
              gap: 13px;
              h6 {
                font-size: 16px;
                font-family: Lato;
                font-weight: 400;
              }
              .vector {
                background-color: rgb(49, 49, 49);
                padding: 5px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 10px;
                  height: 10px;
                }
              }
            }
            &-right {
              display: flex;
              align-items: center;
              gap: 40px;
              h6 {
                font-family: Lato;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
              }
              span {
                font-family: Lato;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.16px;
                color: rgba(209, 209, 209, 1);
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .course-content-course {
    .course-content {
      width: 96%;
      &-lists {
        .list {
          &-second {
            .isExpanded {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .course-content-course {
    .course-content {
      width: 100%;
      padding: 0;
      &-lists {
        .list {
          &-first{
            flex-direction: column;
            img{
              width: 14px;
              height: 14px;
            }
            span{
              font-size: 14px;
line-height: 17px;
            }
          }
        
          &-second {
            gap: 20px;
            .isExpanded {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.notes {
  //  padding: 0 159px;
  // padding: "10px 10px";
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // gap: 32px;
  column-gap: 15px;
  a{
    margin-bottom: 15px;
    
   
  }
  .written-notes {
    display: flex;
    flex-direction: column;
    gap: 24px;
    h5 {
      color: #c07600;
      font-family: Lato;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.24px;
    }
    .notes-area {
      display: flex;
      flex-direction: column;
      gap: 16px;
      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        span {
          color: #939393;
          font-family: Lato;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.12px;
        }
        .note {
          border-radius: 2px;
          background: #222;
          // display: flex;
          
          padding: 16px;
          // align-items: flex-start;
          gap: 16px;
          .mainComment{
            display: flex;
          }
          textarea {
            outline: none;
            resize: none;
            overflow: hidden;
            width: 100%;
            padding: 10px 12px;
            border-radius: 2px;
            border: 2px solid #c07600;
            background-color: transparent;
            color: #fff;
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.48px;
            &:focus {
              background-color: #222222;
            }
          }
          .parentUserComment {
            width: 100% !important;
            color: #f3f3f3;
            font-family: Lato;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.28px;
            margin-bottom: 10px;
            display: flex;
            .userImage{
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            .nameAndComments{
              background-color: rgb(243, 233, 233);
              width: 100%;
              border-radius: 8px;
              .fileComment{
                display: flex;
                // justify-content: center;
                align-items: center;
                cursor: pointer;
              }
              .userName{
                padding: 2px 0 2px 10px;
                color: black;
                font-weight: 700;
                
              }
            }
          }
          .right-side {
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            // gap: 16px;
            padding-left: 5px!important;
            flex-shrink: 0;
            padding-left: 16px;
            border: 1px solid;
            border-width: 0 0 0 1px;
            border-image: conic-gradient(
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                #d58b0e,
                #d58b0e,
                #d58b0e,
                transparent,
                transparent
              )
              1;
            .icon-trash,
            .icon-edit {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .new-note {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    margin-bottom: 15px;
    textarea {
      outline: none;
      resize: none;
      overflow: hidden;
      width: 100%;
      height: 172px;
      padding: 10px 12px;
      border-radius: 2px;
      border: 2px solid #c07600;
      background-color: transparent;
      color: #fff;
      font-family: Lato;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.48px;
      &:focus {
        background-color: #222222;
      }
     
    }
    .addFileLabel{
      color:white;
      cursor: pointer;
      border: 1px solid #EE9B07;
      padding: 10px;
      border-radius: 8px;
    }
    #addFile{
      display: none;
    }
    button {
      background: #c07600;
      color: #fff;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.16px;
    }
  }
}
