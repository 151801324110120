.payment-section {
  padding-top: 117px;
  width: 100%;
  .payment-section-container {
    padding-bottom: 60px;
    display: flex;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 850px) {
  .payment-section {
    .payment-section-container {
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
    }
  }
}
