.testimonials-section {
  padding: 30px 0;
  width: 100%;
  .testimonials-section-container {
    padding: 36px,0px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    .modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.6);
      display: flex;
      align-items: flex-start; // modal-content'i dikey olarak merkezler
      justify-content: flex-start; // modal-content'i yatay olarak merkezler
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
      z-index: 1000; // Diğer öğelerin üzerinde olması için z-index değerini artırın

      &.active {
          opacity: 1;
          visibility: visible;
      }
    
      .modal-content {
          background-color: black; // Arka plan rengini beyaz olarak belirtin
          padding: 20px;
          border-radius: 5px;
          border: 1px solid rgb(232, 163, 69);
          position: absolute;
          width: 80%; // İstediğiniz genişliğe göre ayarlayabilirsiniz
          max-width: 600px; // İstediğiniz maksimum genişliğe göre ayarlayabilirsiniz
          display: flex;
          overflow: auto;
          flex-direction: row;
          .modalImage{
            border: none;
            border-radius: none!important;
            img{
              border: none;
              border-radius: 0%!important;
            }
          }
          .modalInfo{
            .close-button {
              position: absolute;
              right: 10px;
              top: 10px;
              cursor: pointer;
          }
          }
      }
    }
    
  
    .testimonials {
      // border: 1px solid rgba(192, 118, 0, 1);
      // border-style: solid none none solid;
      padding: 40px 0px 24px ;
      img{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
      &_content {
        // width: 100%;
        // position: relative;
        // padding: 0 96px;
        .thought-area {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 40px;
          .testimonial {
            display: flex;
            flex-direction: column;
            
            gap: 24px;
            padding: 0 0 40px 0;
            .imageTest{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .opinion {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 24px;
              .name-skill {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 8px;
                h6 {
                  font-size: 24px;
                  font-weight: 600;
                  line-height: 29px;
                  color: white;
                }
                span {
                  color: rgba(255, 255, 255, 0.66);
                  font-size: 16px;
                  line-height: 20px;
                }
              }
              .text-area {
                padding-bottom: 40px;
                padding-right: 10px;
                display: flex;
                align-items: center;
                // border: 1px solid rgba(192, 118, 0, 1);
                // border-style: none solid solid none;
                 p {
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  cursor: pointer;
                }
            
                
              }
            }
          }
          .changers {
            display: flex;
            gap: 8px;
            li {
              border-radius: 2px;
              border: none;
              width: 8px;
              height: 8px;
              background-color: white;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .testimonials-section {
    .testimonials-section-container {
      .testimonials {
        padding: 40px;

      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .testimonials-section {
    .testimonials-section-container {
      h2{
        font-size: 36px;
      }
      .testimonials {
        &_content {
          .thought-area {
            .testimonial {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .testimonials-section {
    .modalImage{
      display: none;
    }
    .modalInfo{
      overflow: scroll;
    }
    .testimonials-section-container {
      .testimonials {
        padding: 40px 10px;
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .testimonials-section {
    .testimonials-section-container {
      padding: 0;
      h2 {
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .testimonials-section {
    .testimonials-section-container {
      .testimonials {
        &_content {
          padding: 0 50px;
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .testimonials-section {
    .testimonials-section-container {
      .testimonials {
        &_content {
          padding: 0 35px;
          .thought-area{
            .testimonial{
              .opinion{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
