.instructor {
  padding: 30px 0;
  width: 100%;
  .instructor-container {
    padding: 36px 3px;;
    display: flex;
    flex-direction: column;
    gap: 40px;
    &_about {
      background: linear-gradient(to bottom, rgb(24, 24, 26), rgb(49, 49, 49));
      display: flex;
      gap: 24px;
      align-items: center;
      img{
        padding-left: 25px;
        padding: 35px 25px;
      }
      &_right {
        position: relative;
        // padding: 45px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        font-size: 20px;
        line-height: 38px;
        font-weight: 400;
        padding: 0px 25px;
        p {
          text-align: center;
          color: white;
          font-feature-settings: "clig" off, "liga" off;
          text-align: start;
        }
      }
      .top-left {
        z-index: -9999;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 3px solid;
        border-image: conic-gradient(
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            rgba(192, 118, 0, 1),
            transparent
          )
          1;
      }
      .bottom-right {
        position: absolute;
        z-index: -9999;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 3px solid;
        border-image: conic-gradient(
            transparent,
            transparent,
            transparent,
            rgba(192, 118, 0, 1),
            transparent,
            transparent,
            transparent,
            transparent,
            transparent
          )
          1;
      }
    }
  }
  
}

@media only screen and (max-width: 1050px) {
  .instructor {
    .instructor-container {
      h2{
        text-align: left;
      }
      &_about {
        &_right {
          padding: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .instructor {
    .instructor-container {
      h2{
        font-size: 36px;
      }
      padding: 0;
      &_about {
        flex-direction: column;
       
        .top-left {
          border-image: conic-gradient(
              rgba(192, 118, 0, 1),
              transparent,
           
              rgba(192, 118, 0, 1),
              rgba(192, 118, 0, 1)
            )
            1;
        }
        .bottom-right {
          border-image: conic-gradient(
              transparent,
             
              rgba(192, 118, 0, 1),
              rgba(192, 118, 0, 1),
              
            )
            
        }
      }
    }
  }
}