.hero_section {
  padding-top: 135px;
  width: 100%;
  .main-hero {
    display: flex;
    &-left {
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      gap: 48px;
      background: linear-gradient(
        to bottom,
        rgba(24, 24, 26, 1),
        rgba(49, 49, 49, 1)
      );
      padding: 83px 23px;
      .texts {
        .special-header-text *{
          font-size: 47px;
          font-weight: 900;
          // line-height: 62px;
          color: rgba(213, 139, 14, 1) !important;        
          font-family: "Montserrat", sans-serif !important;
          p{
            font-family: "Montserrat", sans-serif !important;
          }
        }
          
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      button {
        &:hover {
          border-color: rgba(142, 79, 0, 1);
        }
      }
    }
    &-right {
      width: 67%;
      background: linear-gradient(180deg,#18181a,#313131);
    }
  }
}
.main-hero-right img{
  width: 100%;
}
@media only screen and (max-width: 1300px) {
  .hero_section {
    .main-hero {
      &-left {
        width: 37%;
      }
      &-right {
        width: 63%;
        max-width: 630px;
        img {
         
          max-width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .hero_section {
    .main-hero {
      &-left {
        width: 44%;
      }
      &-right {
        width: 56%;
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .hero_section {
    .main-hero {
      &-left {
        width: 50%;
      }
      &-right {
        width: 50%;
      }
    }
  }
}
.main-hero-right video{
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 650px) {
  
  .hero_section {
    padding-top: 60px;
    .main-hero {
      .texts .special-header-text * {
        font-size: 41px;
        font-family: "Roboto", sans-serif !important;
      }
      display: block;
      &-left {
        font-size: 36px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 75px 15px 23px;
        text-align: center;
      }
      &-right {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .hero_section {
    .container {
      margin: 0;
      width: auto;
    }
  }
}
