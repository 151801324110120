.address {
  background-color: rgba(34, 34, 34, 1);
  width: 68.4%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  gap: 40px;
  height: 668px;
  &_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    h5 {
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.24px;
    }
    &_fields {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .triple-inputs {
        display: flex;
        gap: 16px;
      }
    }
  }
  &_extras {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .pad {
      padding-bottom: 24px;
      width: 100%;
      border: 1px solid;
      border-width: 0 0 1px 0;
      border-image: conic-gradient(
          transparent,
          transparent,
          transparent,
          transparent,
          #d58b0e,
          #d58b0e,
          #d58b0e,
          #d58b0e,
          #d58b0e,
          transparent,
          transparent,
          transparent,
          transparent
        )
        1;
    }
    &_field {
      display: flex;
      gap: 16px;
      align-items: center;
      p {
        color: #fff;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.16px;
      }
    }
  }
  &_update-billing {
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid rgba(192, 118, 0, 1);
    padding: 12px 16px;
    color: #c07600;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
  }
}
@media only screen and (max-width: 1050px) {
  .address {
    height: 836px;
    width: 50%;
    &_header {
      &_fields {
        .triple-inputs {
          flex-direction: column;
          .triple {
            width: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .address {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  .address {
    width: 100%;
  }
}
