.cards-downloads {
  padding: 30px 0;
  width: 100%;
  .cards-downloads-container {
    padding: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    li {
      // border: 1px solid rgba(213, 139, 14, 1);
      background-color: #000;
      border-radius: 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 30.45%;
      transition: 0.5s;
      overflow: hidden;
      height: 385px;
      .image-side {
        position: relative;
        height: 72%;
        .icon-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer !important;
        }
      }
      .info_card {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 24px;
        &_content {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          .icon-film {
            flex-shrink: 0;
          }
          p {
            color: #f3f3f3;
            // font-family: Lato;
            text-align:"center";
            font-weight:"600";
            font-size:"22px";
            line-height: 24px;
          }
          span {
            color: #b9b9b9;
            text-align: right;
            // font-family: ron;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
        button {
          cursor: pointer;
          width: 100%;
          // background-color: #121212;
          border: none;
          background-color: rgb(35, 35, 35);
          padding: 12px 16px;
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: center;
          span {
            color: #d58b0e;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .cards-downloads {
    .cards-downloads-container {
      li {
        width: 39%;
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .cards-downloads {
    .cards-downloads-container {
      li {
        width: 43%;
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .cards-downloads {
    .cards-downloads-container {
      li {
        width: 48%;
        .info_card {
          padding: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .cards-downloads {
    .cards-downloads-container {
      li {
        .image-side {
          max-width: 100%;
          height: auto;
          .main_image {
            width: 100%;
          }
        }
        .info_card {
          padding: 8px;
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .cards-downloads {
    .cards-downloads-container {
      li {
        width: 47%;
        height: auto;
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .cards-downloads {
    .cards-downloads-container {
      padding: 0;
      li {
        .info_card {
          gap: 24px;
          p {
            font-size: 11px;
            line-height: 14px;
          }
          button {
            width: 80%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .cards-downloads {
    .cards-downloads-container {
      li {
        .info_card {
          padding: 12px 8px;
          &_content {
            gap: 4px;
            span {
              font-size: 10px;
            }
          }
          button {
            width: 100%;
            span {
              font-size: 12px;
            }
            .icon-download {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .cards-downloads {
    .cards-downloads-container {
      li {
        width: 100%;
        line-height: 30px;

        .info_card{
          p{
            line-height: 30px;
          }
        }
      }
    }
  }
}
