* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  cursor: default;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  background-color: #121212;
}

a {
  cursor: pointer;
  text-decoration: none;
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
}
input {
  outline: none;
}
.secure {
  display: flex;
  gap: 12px;
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.14px;
  }
}
.container {
  width: 1250px;
  margin: 0 auto;
}
// ! swipper
.swiper-button-prev,
.swiper-button-next {
  color: rgba(255, 255, 255, 1) !important;
}
.swiper-pagination {
  bottom: -3px !important;
}
.swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 1) !important;
}
.swiper-pagination-bullet-active {
  background-color: rgba(213, 139, 14, 1) !important;
}
// ! radio
.radio {
  .label {
    display: flex;
    align-items: center;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: "";
        background: transparent;
        border-radius: 100%;
        border: 2px solid rgba(213, 139, 14, 1);
        display: inline-block;
        width: 22px;
        height: 22px;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: rgba(213, 139, 14, 1);
          box-shadow: inset 0 0 0 4px rgba(34, 34, 34, 1);
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: rgba(213, 139, 14, 1);
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px white;
          border-color: rgba(213, 139, 14, 1);
          background: rgba(213, 139, 14, 1);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

//! checkbox
.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 12px;
  color: white;
}
input[type="checkbox"] {
  visibility: hidden;
  width: 18px;
  height: 18px;
}
.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid rgba(213, 139, 14, 1);
  background-color: transparent;
}
.custom-checkbox input:checked ~ .geekmark {
  background-color: rgba(213, 139, 14, 1);
}
.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-checkbox input:checked ~ .geekmark:after {
  display: block;
}
.custom-checkbox .geekmark:after {
  left: 5px;
  bottom: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.triple-inputs {
  display: flex;
  gap: 16px;
  .triple {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 33%;
    label {
      color: #fff;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.12px;
    }
    input {
      outline: none;
      display: flex;
      padding: 12px;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      color: white;
      border: 1px solid rgba(192, 118, 0, 1);
      border-radius: 2px;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
      line-height: normal;
    }
  }
}
.full-inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    color: #fff;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
  input {
    outline: none;
    display: flex;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: white;
    border: 1px solid rgba(192, 118, 0, 1);
    border-radius: 2px;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: normal;
  }
  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid rgba(192, 118, 0, 1);
    border-radius: 2px;
    background-color: transparent;
    input {
      outline: none;
      width: 70%;
      display: flex;
      padding: 12px;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border: none;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
      line-height: normal;
      color: #b9b9b9;
    }
    .show-hide {
      padding-right: 12px;
    }
    .cards {
      padding: 7px 12px;
    }
  }
}
.half-inputs {
  display: flex;
  gap: 16px;
  .half {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 49%;
    label {
      color: #fff;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.12px;
    }
    input {
      outline: none;
      display: flex;
      padding: 12px;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      color: white;
      border: 1px solid rgba(192, 118, 0, 1);
      border-radius: 2px;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
      line-height: normal;
    }
    .input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: 1px solid rgba(192, 118, 0, 1);
      border-radius: 2px;
      background-color: transparent;

      input {
        outline: none;
        width: 100%;
        display: flex;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        color: white;
        border: none;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        line-height: normal;
      }
      .cvc {
        padding: 7px 12px;
      }
    }
  }
}
.rotate {
  transform: rotate(180deg);
}
button {
  font-family: "Montserrat", sans-serif;
  padding: 12px 16px;
  border: 1px solid rgba(192, 118, 0, 1);
  border-radius: 2px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
}
.special-header-text {
  color: rgba(213, 139, 14, 1) !important;
}

.special-text {
  color: rgba(246, 188, 117, 1) !important;
}
p {
  font-family: 'Roboto', sans-serif !important;
  color: white;
}

span {
  font-family: 'Roboto', sans-serif !important;
  color: white;
}
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}

//! Switch input
.switch {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 32px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(42, 42, 42, 1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: rgba(115, 115, 115, 1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-input:checked + .slider {
  background-color: rgba(42, 42, 42, 1);
}
.switch-input:checked + .slider:before {
  content: "";
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
  background-color: rgba(213, 139, 14, 1);
}

.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 50%;
}
.slider.round::before {
  content: "";
  background-image: url("./assets/svg/Unchecked.svg");
  background-size: cover;
}
.switch-input:checked + .slider.round::before {
  background-image: url("./assets/svg/Checked.svg");
}
//! Select style
.css-13cymwt-control,
.css-t3ipsp-control {
  background-color: transparent !important;
  border: 1px solid rgba(192, 118, 0, 1) !important;
  border-radius: 2px !important;
  box-shadow: none !important;
}
.css-1nmdiq5-menu {
  background-color: #222 !important;
  border: 1px solid rgba(192, 118, 0, 1) !important ;
  color: white;
}
.css-qbdosj-Input,
.css-1dimb5e-singleValue {
  color: white !important;
}
.css-1fdsijx-ValueContainer {
  padding: 8px 12px !important;
}
.css-1jqq78o-placeholder {
  color: white !important;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-tj5bde-Svg {
  width: 24px !important;
  height: 24px !important;
}
.css-1xc3v61-indicatorContainer {
  padding: 8px 12px !important;
}

@media only screen and (max-width: 1300px) {
  .container {
    width: 1000px;
  }
}
@media only screen and (max-width: 1050px) {
  .container {
    width: 900px;
  }
}
@media only screen and (max-width: 950px) {
  .container {
    width: 800px;
  }
}
@media only screen and (max-width: 850px) {
  .container {
    width: 700px;
  }
}
@media only screen and (max-width: 750px) {
  .container {
    width: 600px;
  }
}
@media only screen and (max-width: 650px) {
  .container {
    width: 500px;
  }
}
@media only screen and (max-width: 550px) {
  .container {
    width: 400px;
  }
}
@media only screen and (max-width: 450px) {
  .container {
    width: 350px;
  }
}

#credential_picker_container{
  z-index: 100269999 !important;
}

del{
  text-decoration: line-through !important;
}

.hidden-nav{
  display: none;
}