.course-card {
  position: absolute;
  .course-card-container {
    box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.44);
    display: flex;
    flex-direction: column;
    // .video-part {
    //   height: 238px;
    // }
    .detail-part {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      background-color: rgba(34, 34, 34, 1);
      h5 {
        font-size: 36px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.36px;
      }
      .buy-button {
        background-color: rgba(192, 118, 0, 1);
        color: white;
        padding: 12px 16px;
        line-height: normal;
        letter-spacing: 0.16px;
      }
      .includes {
        display: flex;
        flex-direction: column;
        gap: 24px;
        p {
          font-size: 16px;
          line-height: normal;
          letter-spacing: 0.16px;
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          li {
            display: flex;
            gap: 16px;
          }
        }
      }
      .share-button {
        background-color: transparent;
        padding: 12px 16px;
        color: rgba(232, 163, 69, 1);
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        border: none;
        &:hover {
          color: white;
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .course-card {
    padding: 24px 100px;
  }
}
@media only screen and (max-width: 650px) {
  .course-card {
    padding: 24px 70px;
  }
}
@media only screen and (max-width: 550px) {
  .course-card {
    padding: 24px 20px;
  }
}
@media only screen and (max-width: 450px) {
  .course-card {
    padding: 24px 0;
  }
}
