.contact-section {
    padding: 20px;
    background-color: #121212; /* Dark background */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: white; /* White text for contrast */
}

.contact-title {
    color: white; 
    text-align: start;
    margin-bottom: 20px;
}

.special-text {
    color: #c07600 !important;
}

.contact-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.contact-item {
    display: flex;
    align-items: center;
    margin: 10px;
    flex-basis: 100%; /* Full width on smaller screens */
    padding: 10px; /* Padding for spacing within the item */
    box-sizing: border-box; /* Includes padding in the width calculation */
}

.icon {
    width: 32px;
    height: 32px;
    margin-right: 15px;
    fill: #C07600; /* Orange color for the SVG icons */
    color: #C07600; /* Ensuring the color applies to all icon types */
}

.contactv2 h4 {
    margin: 0 0 5px 0;
    color: #ffffff; /* White color for headers within contact items */
}

.contactv2 p, a {
    color: #ffffff; /* White text for readable contrast */
    text-decoration: none;
    font-size: 14px;
}

a:hover {
    color: #C07600; /* Orange color when hovering over links */
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .contact-item {
        flex-basis: 30%; /* Adjusts to 30% width on medium and larger screens */
    }
}

@media (max-width: 767px) {
    .contact-info {
        flex-direction: column;
    }

    .contact-item {
        flex-direction: column; /* Stack the icon and text vertically on small screens */
        align-items: flex-start; /* Aligns items to the start of the contact item */
    }

    .icon {
        margin-bottom: 10px; /* Adds space between the icon and text on small screens */
    }
}
