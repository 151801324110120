.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    width: 400px;
    height: 233px;
    display: inline-flex;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2a2a2a;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    .head {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      h3 {
        color: #c07600;
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.24px;
      }
      p {
        color: #f3f3f3;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        padding-bottom: 40px;
      }
    }
    .buttons {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      button {
        padding: 12px 16px;
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        border: none;
      }
      .red {
        background-color: rgba(255, 69, 58, 1);
      }
      .green {
        background-color: rgba(48, 209, 88, 1);
      }
    }
  }
}
.modal.open {
  display: flex;
}

@media only screen and (max-width: 450px) {
  .modal {
    .modal-content {
      width: 361px;
    }
  }
}
