.accordion {
  padding: 30px 0;
  width: 100%;
  .accordion-container {
    padding: 36px 0px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    h3 {
      font-size: 48px;
      font-weight: 700;
      line-height: 40px;
      
    }
    h4 {
      display: none;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
    }
    &_lists {
      display: flex;
      flex-direction: column;
      gap: 8px;
      li {
        border: 1px solid rgba(213, 139, 14, 1);
        border-style: none none solid none;
        border-radius: 1px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
        .accordion-question {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          span {
            cursor: pointer;
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
            color: white;
          }
          .rotate {
            transform: rotate(180deg);
            transition: transform 0.3s ease;
          }
        }
        p {
          font-size: 16px;
          line-height: 20px;
          color: white;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease;
        }
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .accordion {
    .accordion-container {
      padding: 0;
      h3 {
        display: none;
      }
      h4 {
        font-size: 36px;
        display: block;
      }
      &_lists {
        li {
          p {
            line-height: 24px;
          }
        }
      }
    }
  }
}
