.player-side-content {
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  .player-side {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .head {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      .icon-film {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
      }
      h5 {
        color: #f3f3f3;
        font-family: Lato;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
      }
    }
    .video-area {
      width: 100%!important;
      height: 100%!important;
      // background-color: #121212;
      video{
        // width: 100%!important;
      height: 100%; 
        box-sizing: content-box;
        position: relative;
        padding-bottom: 20px;
        z-index: -1;
      }
      video::-webkit-media-controls-panel {
        // background-image: gray !important;
        
        position: absolute;
      bottom: -18px;
      width: 100%;
        z-index: 1000!important;
        opacity: .5;
        height: max-content;
         background-color: transparent;
        
       }
     

       video::-webkit-media-controls-play-button{
        background-color:#EE9B07;
        border-radius: 100%;
        opacity: .5;
        margin-right: 5px;
        margin-bottom: 1px;
       }
       video::-webkit-media-controls-play-button:hover{
        opacity: 1;
       }
       
    
       video::-webkit-media-controls-current-time-display{
        color: #EE9B07;
        z-index: 99999999;
       }
      
      video::-webkit-media-controls-time-remaining-display{
        color: #EE9B07;
      }
    }

    video::-webkit-media-controls-panel {
      height: 102% !important;
      margin-bottom: 10px;
  }

  video::-webkit-media-controls-timeline {
    
height: 5px !important;
}



    .video-area-2,
    .video-area-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 564px;
      background: #222;
      margin: 0 auto;
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            color: #fff;
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0.16px;
          }
          p {
            color: #fff;
            font-family: Lato;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0.2px;
          }
        }
        .button-info {
          display: flex;
          flex-direction: column;
          gap: 20px;
          button {
            background: #c07600;
            color: #fff;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
          }
          p {
            color: #fff;
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0.16px;
          }
        }
      }
    }
    .video-area-3 {
      .info {
        button {
          display: flex;
          align-items: center;
          gap: 16px;
          background: #c07600;
          color: #fff;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.16px;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        background-color: transparent;
        display: flex;
        gap: 16px;
        align-items: center;
        color: #f3f3f3;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        .icon-right {
          width: 24px;
          height: 24px;
        }
      }
      .download-button {
        color: #c07600;
        font-size: 16px;
        line-height: normal;
        letter-spacing: 0.16px;
      }
    }
  }
  
  .notes {
    //  padding: 0 159px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // gap: 32px;
    column-gap: 15px;
    a{
      margin-bottom: 15px;
      
     
    }
    .written-notes {
      display: flex;
      flex-direction: column;
      gap: 24px;
      h5 {
        color: #c07600;
        font-family: Lato;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.24px;
      }
      .notes-area {
        display: flex;
        flex-direction: column;
        gap: 16px;
        li {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          span {
            color: #939393;
            font-family: Lato;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.12px;
          }
          .note {
            border-radius: 2px;
            background: #222;
            // display: flex;
            
            padding: 16px;
            // align-items: flex-start;
            gap: 16px;
            .mainComment{
              display: flex;
            }
            textarea {
              outline: none;
              resize: none;
              overflow: hidden;
              width: 100%;
              padding: 10px 12px;
              border-radius: 2px;
              border: 2px solid #c07600;
              background-color: transparent;
              color: #fff;
              font-family: Lato;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0.48px;
              &:focus {
                background-color: #222222;
              }
            }
            .parentUserComment {
              width: 100% !important;
              color: #f3f3f3;
              font-family: Lato;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.28px;
              margin-bottom: 10px;
              display: flex;
              .userImage{
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }
              .nameAndComments{
                background-color: rgb(243, 233, 233);
                width: 100%;
                border-radius: 8px;
                .fileComment{
                  display: flex;
                  // justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
                .userName{
                  padding: 2px 0 2px 10px;
                  color: black;
                  font-weight: 700;
                  
                }
              }
            }
            .right-side {
              display: flex;
              height: 100%;
              flex-direction: column;
              align-items: flex-start;
              // gap: 16px;
              padding-left: 5px!important;
              flex-shrink: 0;
              padding-left: 16px;
              border: 1px solid;
              border-width: 0 0 0 1px;
              border-image: conic-gradient(
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  #d58b0e,
                  #d58b0e,
                  #d58b0e,
                  transparent,
                  transparent
                )
                1;
              .icon-trash,
              .icon-edit {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .new-note {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      width: 100%;
      margin-bottom: 15px;
      textarea {
        outline: none;
        resize: none;
        overflow: hidden;
        width: 100%;
        height: 172px;
        padding: 10px 12px;
        border-radius: 2px;
        border: 2px solid #c07600;
        background-color: transparent;
        color: #fff;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.48px;
        &:focus {
          background-color: #222222;
        }
       
      }
      .addFileLabel{
        color:white;
        cursor: pointer;
        border: 1px solid #EE9B07;
        padding: 10px;
        border-radius: 8px;
      }
      #addFile{
        display: none;
      }
      button {
        background: #c07600;
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.16px;
      }
    }
  }
  .see-parts {
    display: none;
    background-color: transparent;
    color: #c07600;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
  }
}

.userComment {
  text-align: left;
   background-color: gray;
   padding: 5px;
   border-radius: 8px;
   width: 100%!important;
}
.parentTeacherComment{
  width: 100%!important;
  display: flex;
  justify-content: flex-end;
}
.teacherComment {
  // text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 90%!important;
  background-color: rgb(243, 233, 233);
  border-radius: 8px;
  .fileComment{
    display: flex;
    align-items: center;
  }
  span{
    padding: 2px 0 2px 10px;
    color: #D58B0E!important;
    font-weight: 700!important;
  }
  
}
.teacherCommentInfo{
  background-color: gray;
  padding: 5px;
  border-radius: 8px;
  width: 100%;
  font-size: 12px;
}
.LeaveNote{
  display: flex;
  p{
    display: flex;
  }
}
.loader {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  border-radius: 50%;
}

.loader {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  width: 20px;
  height: 20px;
}

.loader {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  width: 20px;
  height: 20px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}





@media only screen and (max-width: 950px) {
  .player-side-content {
    .player-side {
      .head {
        h5 {
          font-size: 20px; /* Başlık boyutunu azalt */
        }
      }
    }
    .video-area video {
      padding-bottom: 30px; /* Daha küçük ekranlar için padding azalt */
    }
  }
}
@media only screen and (max-width: 750px) {
  .player-side-content {
    .player-side {
      .video-area,
      .video-area-2,
      .video-area-3 {
        height: 650px;
      }
    }
    .see-parts {
      display: block;
    }
  }
}
@media only screen and (max-width: 550px) {
  .player-side-content {
    margin-top: 27px;

    .player-side {
      .video-area,
      .video-area-2,
      .video-area-3 {
        height: 600px; 
      }
      .buttons {
        flex-direction: column;
        gap: 16px;
        button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .player-side-content {
    padding: 0 10px; 
    .player-side {
      .video-area,
      .video-area-2,
      .video-area-3 {
        // width: 330px;
        height: 400px;
        span{
          text-align: center;
        }
      }
      .buttons {
        flex-direction: column;
        gap: 16px;
        button {
          width: 100%;
          justify-content: center;
          
        }
        span{
            text-align: center;
        }
      }
    }
  }
}

// .rpv-toolbar__right .rpv-core__minimal-button .rpv-core__icon{
//   display: none !important;
// }
.rpv-toolbar__right .rpv-core__display--block-medium:nth-of-type(4) {
  display: none !important;
}
