.hero-section-course {
  padding-top: 135px;
  width: 100%;
  .container {
    .expanded {
      display: none;
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      padding-bottom: 24px;
    }
    .hero-section-course-container {
      display: flex;
      position: relative;
      padding: 60px;
      gap: 24px;

      &-left {
        width: 66%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        &_header {
          display: flex;
          flex-direction: column;
          gap: 24px;
          h1 {
            font-size: 48px;
            font-weight: 700;
            line-height: 60px;
          }
          p {
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.2px;
          }
        }
        &_student-rate {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 5px;
          .rate {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
              color: rgba(243, 243, 243, 1);
              font-size: 16px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.16px;
            }
            ul {
              display: flex;
              align-items: center;
              gap: 4px;
            }
            .people {
              font-size: 14px;
              line-height: normal;
              letter-spacing: 0.14px;
              color: rgba(185, 185, 185, 1);
            }
          }
          span {
            font-size: 15px;
            line-height: normal;
            letter-spacing: 0.15px;
            .special-header-text {
              font-weight: 500;
            }
          }
        }
        &_details {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .update-time,
          .language {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
      .bottom-left {
        z-index: -9999;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 3px solid;
        border-image: conic-gradient(
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            rgba(192, 118, 0, 1),
            transparent,
            transparent,
            transparent
          )
          1;
      }
      .top-right {
        position: absolute;
        z-index: -9999;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 3px solid;
        border-image: conic-gradient(
            transparent,
            rgba(192, 118, 0, 1),
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            transparent
          )
          1;
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .hero-section-course {
    .container {
      .hero-section-course-container {
        &-left {
          width: 55%;
          &_header {
            h1 {
              display: block;
              font-size: 30px;
            }
          }
          &_student-rate {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 16px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .hero-section-course {
    .container {
      .hero-section-course-container {
        &-left {
          width: 50%;
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .hero-section-course {
    .container {
      .expanded {
        display: block;
      }
      .hero-section-course-container {
        padding: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        &-left {
          width: 42%;
          &_header {
            h1 {
              display: block;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .hero-section-course {
    .container {
      .hero-section-course-container {
        padding: 0;
        flex-direction: column;
        &-left {
          padding: 24px;
          width: 100%;
          &_header {
            p {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0.16px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .hero-section-course {
    .container {
      .hero-section-course-container {
        padding: 0;
        flex-direction: column;
        &-left {
          padding: 24px;
          width: 100%;
          &_header {
            p {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0.16px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 550px) {
}
@media only screen and (max-width: 450px) {
  .hero-section-course {
    .container {
      .expanded {
        display: block;
      }
      .hero-section-course-container {
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        &-left {
          padding: 24px;
          width: 100%;
          &_header {
            h1 {
              display: block;
              font-size: 25px;
            }
            p {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0.16px;
            }
          }
        }
        .bottom-left {
          border-image: conic-gradient(
              rgba(192, 118, 0, 1),
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              rgba(192, 118, 0, 1),
              rgba(192, 118, 0, 1)
            )
            1;
        }
        .top-right {
          border-image: conic-gradient(
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              rgba(192, 118, 0, 1),
              rgba(192, 118, 0, 1),
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent
            )
            1;
        }
      }
    }
  }
}
