.login {
  padding-top: 135px;
  width: 100%;
  padding-bottom: 60px;
  .login-section {
    width: 400px;
    margin: 0 auto;
    border-radius: 2px;
    padding: 24px 40px;
    box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.44);
    background-color: rgba(34, 34, 34, 1);
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
    form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      h6 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
      }
      .inputs-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        label {
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0em;
          color: white;
        }
        input {
          width: 100%;
          border: 1px solid rgba(192, 118, 0, 1);
          border-radius: 2px;
          background-color: transparent;
          padding: 12px 16px;
          color: white;
          outline: none;
        }
      }
      .pass-remember {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .forget-password {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: 0em;
          color: white;
        }
      }
      .button-login {
        margin-top: 16px;
        width: 100%;
        background-color: rgba(192, 118, 0, 1);
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-align: center;
      }
    }
    .lines {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      hr {
        color: rgba(239, 244, 254, 1);
        width: 142px;
        background-color: rgba(239, 244, 254, 1);
        height: 1px;
      }
      span {
        color: white;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.01em;
      }
      .line {
        border-radius: 50%;
        padding: 1px;
        background-color: rgba(239, 244, 254, 1);
      }
    }
    .login-section-others {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      li {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: rgba(232, 163, 69, 1);
        }
      }
    }
  }
}
.error-message {
  color: red;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

@media only screen and (max-width: 450px) {
  .login {
    .login-section {
      width: 100%;
    }
  }
}
