.credit-card {
  background-color: rgba(34, 34, 34, 1);
  width: 68.4%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  height: 532px;
  &_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    h5 {
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.24px;
    }
    p {
      color: #30d158;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.14px;
    }
  }
  &_body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  &_upload {
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid rgba(192, 118, 0, 1);
    padding: 12px 16px;
    color: #c07600;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
  }
}
@media only screen and (max-width: 1050px) {
  .credit-card {
    height: 623px;
    width: 50%;
    &_body {
      .first {
        flex-direction: column;
        .half {
          width: 100%;
        }
      }
      .full-inputs {
        .card-number {
          input {
            width: 53%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .credit-card {
    &_body {
      .full-inputs {
        .card-number {
          input {
            width: 47%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .credit-card {
    height: 625px;
    width: 60%;
    &_body {
      .full-inputs {
        .card-number {
          input {
            width: 50%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .credit-card {
    width: 100%;
    &_body {
      .full-inputs {
        .card-number {
          input {
            width: 45%;
          }
        }
      }
    }
  }
}
