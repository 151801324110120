.about {
  padding: 30px 0;
  width: 100%;
  .about-container {
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    h2 {
      text-align: end;
    }
    &_about {
      display: flex;
      flex-direction: row-reverse;
      gap: 24px;
      align-items: center;
      &_right {
        position: relative;
        padding: 67px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;

        p {
          text-align: center;
          color: white;
        }
      }
      .bottom-left {
        z-index: -9999;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 3px solid;
        border-image: conic-gradient(
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            rgba(192, 118, 0, 1),
            transparent,
            transparent,
            transparent
          )
          1;
      }
      .top-right {
        position: absolute;
        z-index: -9999;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 3px solid;
        border-image: conic-gradient(
            transparent,
            rgba(192, 118, 0, 1),
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            transparent
          )
          1;
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
 .about {
    .about-container {
      &_about {
        &_right {
          padding: 10px 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .about {
    .about-container {
      &_about {
        &_right {
          padding: 10px 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .about {
    .about-container {
      padding: 0;
      flex-direction: column;
      gap: 40px;
      h2 {
        text-align: start;
      }
      &_about {
        flex-direction: column;
        &_right {
          padding: 45px 40px;
        }
        .bottom-left {
          border-image: conic-gradient(
              rgba(192, 118, 0, 1),
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              rgba(192, 118, 0, 1),
              rgba(192, 118, 0, 1)
            )
            1;
        }
        .top-right {
          border-image: conic-gradient(
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              rgba(192, 118, 0, 1),
              rgba(192, 118, 0, 1),
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent
            )
            1;
        }
      }
    }
  }
}
