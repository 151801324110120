.scheme-modul {
  display: flex;
  flex-direction: column;
  max-height: 1500px;
  overflow-y: scroll;
  z-index: 9999999999999 !important;
  .section-top {
    width: 100%;
    border-radius: 30px 30px 0 0;
    background-color: #313131;
    padding: 16px;
    display: none;
    align-items: center;
    justify-content: center;
    .line-gray {
      border-radius: 50px;
      width: 32px;
      height: 4px;
      background-color: rgba(147, 143, 153, 1);
    }
  }

  .progress-info {
    background-color: rgba(18, 18, 18);
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    border-bottom: 1px solid #5a2f00;
    h5 {
      color: #f3f3f3;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
    .custom-progress-bar {
      width: 100%;
    }
    span {
      color: #d1d1d1;
      font-family: Lato;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .modul1 {
    display: flex;
    flex-direction: column;
    background-color: rgba(18, 18, 18);
    .title {
      padding: 24px 32px;
      h5 {
        color: #f3f3f3;
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
    }
    .lessons {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      li {
        cursor: pointer;
        display: flex;
        padding: 24px 32px;
        flex-direction: column;
        gap: 24px;
        border-bottom: 1px solid #5a2f00;
        .content {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          width: 100%;
          .icon-film,
          .icon-resources {
            cursor: pointer;
            width: 32px;
            height: 32px;
            flex-shrink: 0;
          }
          p {
            cursor: pointer;
            color: #f3f3f3;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
          span {
            cursor: pointer;
            color: #b9b9b9;
            text-align: right;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .custom-progress-bar {
          width: 100%;
        }
      }
      .selected {
        background-color: #222;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 12px;
    padding: 2px 2px 0 2px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #000;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(238, 155, 7, 1);
    border-radius: 10px;
  }
}
@media only screen and (max-width: 1300px) {
  .scheme-modul {
    max-height: 1540px;
  }
}
@media only screen and (max-width: 1050px) {
  .scheme-modul {
    max-height: 1750px;
  }
}
@media only screen and (max-width: 950px) {
  .scheme-modul {
    .progress-info {
      h5 {
        font-size: 20px;
      }
      span {
        font-size: 16px;
      }
    }
    .modul1 {
      .title {
        h5 {
          font-size: 20px;
        }
      }
      .lessons {
        li {
          .content {
            align-items: flex-start;
            .icon-film,
            .icon-resources {
              width: 28px;
              height: 28px;
            }
            p {
              font-size: 12px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .scheme-modul {
    .progress-info {
      h5 {
        font-size: 24px;
      }
      span {
        font-size: 20px;
      }
    }
    .modul1 {
      .title {
        h5 {
          font-size: 24px;
        }
      }
      .lessons {
        li {
          .content {
            .icon-film,
            .icon-resources {
              width: 32px;
              height: 32px;
            }
            p {
              font-size: 16px;
            }
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
    .section-top {
      display: flex;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
}