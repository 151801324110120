.left-content {
  width: 31.2%;
  margin-top: 79px;
}
.left-content.visible-section {
  opacity: 1;
  display: block;
  height: auto;
  top: 0;
  transform: translateY(0);
  z-index: 999999999;
}
@media only screen and (max-width: 750px) {
  .left-content {
    width: 100%;
    position: absolute;
    display: none;
    opacity: 0;
    height: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    margin-top: 500px;
  }
}