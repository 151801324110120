.contact {
  background-color: rgba(34, 34, 34, 1);
  width: 68.4%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 128px;
  h6 {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.24px;
  }
  ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    li {
      a {
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .contact {
    height: 272px;
    width: 50%;
    ul {
      flex-direction: column;
      gap: 24px;
    }
  }
}
@media only screen and (max-width: 850px) {
  .contact {
    height: 272px;
    width: 60%;
    ul {
      flex-direction: column;
      gap: 24px;
    }
  }
}
@media only screen and (max-width: 650px) {
  .contact {
    width: 100%;
  }
}
