.main-action-payment {
  padding: 60px 60px 0 60px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  &-head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    button {
      border-radius: 2px;
      background: #c07600;
      color: #fff;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.16px;
    }
  }
  .terms {
    color: #939393;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.14px;
  }
}
@media only screen and (max-width: 950px) {
  .main-action-payment {
    padding: 60px 30px 0 30px;
  }
}
@media only screen and (max-width: 750px) {
  .main-action-payment {
    padding: 30px 0 0 0;
  }
}
