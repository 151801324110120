.my-masonry-grid {
  display: flex;
  // margin-left: -30px; 
  width: auto;
  row-gap: 20px;
  column-gap: 10px;
}
.my-masonry-grid_column {
  // padding-left: 10px;
  // padding-top: 10px; 
  background-clip: padding-box;
}
.gallery-item{
  position:relative;
  margin-top: 10px;
}
.gallery-item img {
  width: 100%; 
  // border-radius: 8px;
  cursor: pointer;

}
.gallery-item p{
  position: absolute;
  text-align: center;
  bottom: 10px;
  background-color: rgba(0,0,0, 0.4);  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 8px;
  z-index: 100;
  opacity: 0;
transition: .5s;

}

.gallery-item:hover p{
  transition: .7s;
  opacity: 1;
}