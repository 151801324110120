.hero_section-aboutUs {
  padding-top: 135px;
  width: 100%;
  .main-hero-aboutUs {
    // border-radius: 15px;
    display: flex;
    background: linear-gradient(
      to bottom,
      rgba(18, 18, 18, 1),
      rgba(35, 35, 35, 1)
    );
    &-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 100px;
      padding: 60px;
      .texts {
        display: flex;
        flex-direction: column;
        gap: 40px;
        h1 {
          font-size: 48px;
          font-weight: 700;
          line-height: 60px;
          letter-spacing: 0em;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
        }
      }
      .button-aboutUs {
        border: 3px solid rgba(213, 139, 14, 1);
        border-radius: 2px;
        padding: 5px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 201px;
        span {
          font-size: 20px;
          font-weight: 700;
          color: white;
          line-height: 24.38px;
        }
        img {
          padding: 5px;
          
        }
        &:hover {
          border-color: rgba(142, 79, 0, 1);
        }
      }
    }
    &-right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      img {
        width: 100%;
        flex-shrink: 0;

        // height: 80%;
        // border-radius: 15px;
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .hero_section-aboutUs {
    .main-hero-aboutUs {
     
      &-left {
        gap: 35px;
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .hero_section-aboutUs {
    .main-hero-aboutUs {
      &-left {
        width: 100%;
      }
      &-right {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .hero_section-aboutUs {
    .main-hero-aboutUs {
      &-left {
        padding: 10px;
        .texts {
          h1 {
            font-size: 40px;
            line-height: 48px;
          }
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .hero_section-aboutUs {
    padding-top: 120px;
    .container {
      margin: 0;
      width: auto;
    }
  }
}
