footer {
  width: 100%;
   padding: 48px 0;
  background-color: #d58b0e;
  .footer-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
    .lside-footer {
      display: flex;
      gap: 12px;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 100%;
      span{
        color: black !important;
      }
    }
    .rside-footer {
      display: flex;
      gap: 16px;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      width: 100%;
      span {
        color: black !important;
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .footer-container {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 24px;
    padding: 24px 16px;
    .lside-footer {
      align-items: start !important;
    }
  }
}
// @media only screen and (max-width: 300px){
//     .rside-footer{
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//     }
// }
