.pricing-section-course {
  padding: 30px 0 60px 0;
  width: 100%;
  .pricing-section-container {
    width: 66%;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    &_first-part {
      display: flex;
      flex-direction: column;
      gap: 24px;
      h3 {
        font-family: Lato;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
      }
      .options {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        .option {
          display: flex;
          align-items: flex-start;
          gap: 12px;
          input {
            outline: none;
            width: 22px;
            height: 22px;
            accent-color: rgba(213, 139, 14, 1);
            border: 2px solid rgba(213, 139, 14, 1);
            background-color: transparent;
          }
          label {
            display: flex;
            flex-direction: column;
            gap: 8px;
            h5 {
              color: #fff;
              font-family: Lato;
              font-size: 20px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0.2px;
            }
            span {
              font-family: Lato;
              font-size: 16px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.16px;
              color: rgba(243, 243, 243, 1);
            }
            p {
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.14px;
              color: rgba(185, 185, 185, 1);
            }
            h6 {
              font-family: Lato;
              font-size: 16px;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: 0.16px;
              color: rgba(209, 209, 209, 1);
            }
          }
        }
      }
    }
    .enroll-button {
      width: 309px;
      padding: 12px 16px;
      gap: 16px;
      background-color: rgba(192, 118, 0, 1);
      color: white;
    }
  }
}
@media only screen and (max-width: 1300px) {
}
@media only screen and (max-width: 1050px) {
  .pricing-section-course {
    .pricing-section-container {
      width: 100%;
      &_first-part {
        .options {
          justify-content: flex-start;
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
}
@media only screen and (max-width: 750px) {
  .pricing-section-course {
    padding: 30px 0;
    .pricing-section-container {
      width: 100%;
      &_first-part {
        .options {
          flex-direction: column;
        }
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .pricing-section-course {
    .pricing-section-container {
      padding: 0;
      .enroll-button {
        width: 352px;
      }
    }
  }
}
