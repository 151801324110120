.available-courses {
  padding: 30px 0;
  width: 100%;
  background: linear-gradient(180deg,#18181a,#313131);
  .available-courses-container {
    padding: 36px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    &_cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 24px;
      li {
        // border: 1px solid rgba(213, 139, 14, 1);
        // background-color: rgba(213, 139, 14, 1);
        border-radius: 1px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30.45%;
        transition: 0.5s;
        overflow: hidden;
     
          .info_card h6,
          .info_card .price,
          .info_card .info_card_owner .owner span{
            display: block;
            color: white ;
            cursor: pointer;
          }
        
        .image-side {
          position: relative;
          width: 100%;
          cursor: pointer;
          img{
            width: 100%;
            cursor: pointer;
            height: 100%;
          }
          .comingsoon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 40px;
            button {
              &:hover {
                border-color: rgba(142, 79, 0, 1);
              }
            }
          }
        }
        .info_card {
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          cursor: pointer;
          background-color: #000;
          h6 {
            font-size: 22px;
            font-weight: 600;
            line-height: 26.82px;
            color: white;
            text-align: center;
            cursor: pointer;
          }
          h5 {
            // padding: 9px;
            font-size: 22px;
            font-weight: 600;
            line-height: 26.82px;
            color: white;
            text-align: center;
            cursor: pointer;
          }
          &_owner {
            // display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            cursor: pointer;
            text-align: center;
            .owner {
              // display: flex;
              // gap: 12px;
              // align-items: center;
              // width: 50%;
              img {
                border-radius: 2px;
              }
              span {
                color: #bdbdbd !important;
                font-size: 16px;
                line-height: 19.5px;
                padding:  20px 0;
                text-align: center;
                font-weight: 400;
                line-height: 136%;


                *{
                  color: #bdbdbd !important;
                  font-size: 16px;
                  text-align: center;
                  font-weight: 400;
                  margin:0px;
                  padding:0px;
                }
              }
            }
            .price {
              color: white;
              font-size: 20px;
              font-weight: 600;
              line-height: 29.26px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .available-courses {
    .available-courses-container {
      &_cards {
        li {
          width: 39%;
          .info_card {
            h5 {
              padding: 9px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .available-courses {
    .available-courses-container {
      &_cards {
        li {
          width: 43%;
          .info_card {
            padding: 20px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .available-courses {
    .available-courses-container {
      h2{
        font-size: 36px;
      }
      &_cards {
        li {
          width: 48%;
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .available-courses {
    .available-courses-container {
      &_cards {
        li {
          width: 68%;
        }
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .available-courses {
    .available-courses-container {
      padding: 0;
      &_cards {
        li {
          width: 72%;
        }
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .available-courses {
    .available-courses-container {
      &_cards {
        li {
          width: 89%;
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .available-courses {
    .available-courses-container {
      &_cards {
        li {
          width: 100%;
        }
      }
    }
  }
}
.green{
  color: green !important;
}
