.basket-area-payment-section {
  padding: 60px 60px 0 60px;
  width: 100%;
  .basket-area-payment {
    width: 100%;
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 40px;
    border-radius: 2px;
    background: #222;
    box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.44);
    &_head {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .main-info {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        &_image {
          width: 150px;
          height: 78px;
          border-radius: 2px;
        }
        &_text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 63%;
          p {
            color: #f3f3f3;
            font-family: Lato;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.14px;
          }
          span {
            color: #eff4fe;
            font-family: Lato;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
      &_field {
        display: flex;
        flex-direction: column;
        gap: 12px;
        p {
          color: #f3f3f3;
          font-family: Lato;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.12px;
          text-transform: uppercase;
        }
        .input-field {
          display: flex;
          align-items: center;
          padding-bottom: 40px;
          width: 100%;
          border: 1px solid;
          border-width: 0 0 1px 0;
          border-image: conic-gradient(
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              #d58b0e,
              #d58b0e,
              #d58b0e,
              #d58b0e,
              #d58b0e,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent
            )
            1;
          input {
            width: 83%;
            padding: 13px 12px;
            border: 1px solid #c07600;
            background-color: transparent;
            color: #fff;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            border-radius: 2px 0 0 2px;
          }
          button {
            padding: 12px 16px;
            background-color: #c07600;
            color: #fff;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
            border-radius: 0 2px 2px 0;
          }
        }
      }
    }
    &_total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: #eff4fe;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      span {
        color: #eff4fe;
        text-align: right;
        font-family: Lato;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .basket-area-payment-section {
    padding: 60px 30px 0 30px;
    .basket-area-payment {
      &_head {
        .main-info {
          &_text {
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .basket-area-payment-section {
    .basket-area-payment {
      &_head {
        .main-info {
          &_text {
            flex-direction: row;
            align-items: center;
            gap: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .basket-area-payment-section {
    padding: 30px 0 0 0;
  }
}
