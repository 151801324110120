nav {
  width: 100%;
  background-color: rgba(18, 18, 18, 0.72);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  z-index: 9;
  .navbar-container {
    padding: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-bottom {
      display: none;
    }
    .links {
      display: flex;
      gap: 35px;
      align-items: center;
      color: #e8a345;
      font-weight: 500;
      font-size: 16px;
      li {
        a:link,
        a:visited,
        a:hover,
        a:active {
          color: #e8a345;
        }
       a{
font-size: 16px;
       }
      }
      .search {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          cursor: pointer;
        }
        input {
          border: none;
          background-color: transparent;
          outline: none;
          color: white;
          font-size: 16px;
          width: 100%;
          display: none;
        }
      }
      .active-search {
        border: 1px solid rgba(192, 118, 0, 1);
        padding: 10px;
        border-radius: 15px;
        width: 380px;
        input {
          display: block;
        }
      }
    }
    .end-nav {
      display: flex;
      align-items: center;
      gap: 24px;
      .social {
        display: flex;
        align-items: center;
        gap: 13px;
        li {
          cursor: pointer;
          position: relative;
          .rectangle {
            transition: transform 0.3s ease;
            cursor: pointer;
          }
          .fb,
          .yt,
          .insta {
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -66%);
          }
        }
        &_facebook:hover .rectangle,
        &_instagram:hover .rectangle,
        &_youtube:hover .rectangle {
          transform: rotate(45deg);
        }
        &_facebook:hover .fb,
        &_instagram:hover .insta,
        &_youtube:hover .yt {
          transform: translate(-35%, -66%);
        }
      }
      .account {
        position: relative;
        &-setting {
          background-color: rgba(34, 34, 34, 1);
          position: absolute;
          top: 40px;
          right: 0;
          padding: 12px 16px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 12px;
          border-radius: 2px;
          width: 132px;
          li {
            cursor: pointer;
            width: 100%;
            text-align: center;
            color: #fff;
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            a {
              color: white;
            }
          }
          .border {
            padding-top: 12px;
            border: 1px solid;
            border-width: 1px 0 0 0;
            border-image: conic-gradient(
                #d58b0e,
                #d58b0e,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                #d58b0e,
                #d58b0e
              )
              1;
          }
        }
      }
      button {
        background-color: #c07600;
        color: #ffffff;
        transition: 0.2s;
        &:hover {
          background-color: rgba(142, 79, 0, 1);
          border-color: rgba(142, 79, 0, 1);
        }
      }
    }
    .menu {
      // background-color: rgba(18, 18, 18, 0.72);
      // backdrop-filter: blur(5px);
      background-color: rgba(21, 21, 21, 1);
      z-index: 99999;
      position: fixed;
      top: 117px;
      left: 0;
      width: 100%;
      padding: 12px 16px;
      .menu-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 18px 36px;
        align-items: center;
        justify-content: center;
        .active-search {
          display: flex;
          gap: 10px;
          align-items: center;
          border: 1px solid rgba(192, 118, 0, 1);
          padding: 10px;
          border-radius: 1px;
          width: 380px;
          img {
            cursor: pointer;
          }
          input {
            border: none;
            background-color: transparent;
            outline: none;
            color: white;
            font-size: 16px;
            width: 100%;
          }
        }
        .links {
          width: 100%;
          margin: 0 auto;
          position: relative;
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          gap: 12px;
          align-items: center;

          li {
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            a {
              color: white;
            }
          }
          .top-border {
            z-index: -9999;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 3px solid;
            border-image: conic-gradient(
                rgba(192, 118, 0, 1),
                rgba(192, 118, 0, 1),
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                transparent,
                rgba(192, 118, 0, 1),
                rgba(192, 118, 0, 1)
              )
              1;
          }
          .bottom-border {
            z-index: -9999;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 3px solid;
            border-image: conic-gradient(
                transparent,
                transparent,
                transparent,
                transparent,
                rgba(192, 118, 0, 1),
                rgba(192, 118, 0, 1),
                rgba(192, 118, 0, 1),
                transparent,
                transparent,
                transparent,
                transparent
              )
              1;
          }
        }
        .social {
          display: flex;
          align-items: center;
          gap: 13px;
          li {
            position: relative;
            .rectangle {
              transition: transform 0.3s ease;
            }
            .fb,
            .yt,
            .insta {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -66%);
            }
          }
          &_facebook:hover .rectangle,
          &_instagram:hover .rectangle,
          &_youtube:hover .rectangle {
            transform: rotate(45deg);
          }
          &_facebook:hover .fb,
          &_instagram:hover .insta,
          &_youtube:hover .yt {
            transform: translate(-35%, -66%);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  nav {
    .navbar-container {
      .menu {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  nav {
    .navbar-container {
      .logo {
        width: 250px;
      }
      .links {
        display: none;
      }
      .end-nav {
        gap: 60px;
        .nav-bottom {
          display: block;
        }
        .social {
          display: none;
        }
      }
      .menu {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  nav {
    .navbar-container {
      padding: 36px 0;
      .menu {
        .menu-container {
          padding: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  nav {
    .navbar-container {
      .logo {
        width: 180px;
      }
      .menu {
        .menu-container {
          .links {
            .top-border {
              border-image: conic-gradient(
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1),
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1)
                )
                1;
            }
            .bottom-border {
              border-image: conic-gradient(
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1),
                  rgba(192, 118, 0, 1),
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent,
                  transparent
                )
                1;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  nav {
    .navbar-container {
      .logo {
        width: 134px;
      }
    }
  }
}
